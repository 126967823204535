import React from "react";
// import { Link } from "gatsby";
import { OutboundLink } from "gatsby-plugin-google-analytics";

import { FaGlobe } from "@react-icons/all-files/fa/FaGlobe";

import Head from "../../components/head.js";

import Layout from "../../components/layout.js";

const Tony = () => {
  return (
    <Layout>
      <Head title="Tony Van Den Boomen" />
      <h3>Tony Van Den Boomen</h3>
      <h4>
        <FaGlobe style={{ marginRight: 7, paddingTop: 5 }} />
        Victoria, BC, Canada
      </h4>
      <p>
        <OutboundLink to="https://ello.co/tonyvandenboomen">Ello</OutboundLink>
      </p>

      <img
        src="https://assets0.ello.co/uploads/asset/attachment/15979052/ello-optimized-a7717351.jpg"
        alt="Tony Van Den Boomen art"
      />
      <p>At The Dream Lab</p>
      <p>2023, digital painting, 24 x 18 in.</p>
      <img
        src="https://assets0.ello.co/uploads/asset/attachment/3728024/ello-optimized-4640a440.jpg"
        alt="Tony Van Den Boomen art"
        style={{ marginTop: "4rem" }}
      />
      <p>fleeing a natural disaster of his own making</p>
      <p>2016, watercolour, acrylic and colour pencil on paper, 22x22 in.</p>
      <img
        src="https://assets2.ello.co/uploads/asset/attachment/13431418/ello-optimized-e3f6043e.jpg"
        alt="Tony Van Den Boomen art"
        style={{ marginTop: "4rem" }}
      />
      <p>The Riot That Ended In A Parade</p>
      <p>2021, digital painting, 12 x18 in.</p>
      <img
        src="https://assets1.ello.co/uploads/asset/attachment/6877417/ello-optimized-9c6f2f49.jpg"
        alt="Tony Van Den Boomen art"
        style={{ marginTop: "4rem" }}
      />
      <p>around round one</p>
      <p>2017, colour pencil and acrylic on paper, 15 x 11 in.</p>
      <img
        src="https://assets0.ello.co/uploads/asset/attachment/6884041/ello-optimized-dd14d28f.jpg"
        alt="Tony Van Den Boomen art"
        style={{ marginTop: "4rem" }}
      />
      <p>hand me down hold me up</p>
      <p>
        2017, colour pencil, watercolour and photographs on paper, 15 x 11 in.
      </p>
    </Layout>
  );
};

export default Tony;
